/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
/*prettier-ignore*/
/*prettier-ignore*/
/*prettier-ignore*/
/*prettier-ignore*/
/*prettier-ignore*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    blockquote: "blockquote",
    h2: "h2",
    abbr: "abbr",
    ol: "ol",
    li: "li",
    a: "a",
    p: "p",
    h3: "h3",
    ul: "ul"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.blockquote, null, "\n", React.createElement(_components.h2, null, React.createElement(_components.abbr, {
    title: "Too long, didn't read"
  }, "TL;DR")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Don't use this service for anything ilegal or abusive."), "\n", React.createElement(_components.li, null, React.createElement(_components.abbr, {
    title: "Forwarder.cc"
  }, "We"), " are not responsible if something fails."), "\n", React.createElement(_components.li, null, "This is a pay-as-you-go service, no monthly fees, no commitment."), "\n", React.createElement(_components.li, null, "If you have any questions, ask us at ", React.createElement(_components.a, {
    href: "mailto:say.hi@forwarder.cc"
  }, "say.hi@forwarder.cc"), "."), "\n"), "\n"), "\n", React.createElement(_components.p, null, "By using Forwarder.cc and ", React.createElement(_components.a, {
    href: "https://forwarder.cc"
  }, "https://forwarder.cc"), " website (\"", React.createElement(_components.abbr, {
    title: "Forwarder.cc"
  }, "Our service"), "\", \"", React.createElement(_components.abbr, {
    title: "Forwarder.cc"
  }, "We"), "\" or \"", React.createElement(_components.abbr, {
    title: "Forwarder.cc"
  }, "Us"), "\"), you are\nagreeing to be bound by the following terms and conditions (\"", React.createElement(_components.abbr, {
    title: "This document"
  }, "Terms of Service"), "\"). If you are entering\ninto this agreement on behalf of a company or other legal entity, you represent that you have the\nauthority to bind such entity to these terms and conditions, in which case the terms \"You\" or \"Your\"\nshall refer to such entity."), "\n", React.createElement(_components.h3, null, "1. ", React.createElement(_components.abbr, {
    title: "Forwarder.cc"
  }, "Us"), "e of Your account"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "You must use a valid email address for Your account."), "\n", React.createElement(_components.li, null, "You must not use ", React.createElement(_components.abbr, {
    title: "Forwarder.cc"
  }, "Our service"), " for any illegal or abusive purposes."), "\n", React.createElement(_components.li, null, "You are solely responsible for the security of Your account."), "\n", React.createElement(_components.li, null, "You own or have authorization to use ", React.createElement(_components.abbr, {
    title: "Forwarder.cc"
  }, "Our service"), " from the legal owner of the URL(s) and website\nYou are using ", React.createElement(_components.abbr, {
    title: "Forwarder.cc"
  }, "Our service"), " on."), "\n", React.createElement(_components.li, null, React.createElement(_components.abbr, {
    title: "Forwarder.cc"
  }, "Our service"), " shall only be used in accordance with any and all applicable laws and regulations."), "\n"), "\n", React.createElement(_components.h3, null, "2. Payment and Billing"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.abbr, {
    title: "Forwarder.cc"
  }, "Our service"), " is a pay-as-you-go service. You pay in advance for an amount of credits. You can then\nuse Your purchased credits at any time."), "\n", React.createElement(_components.li, null, "There is no monthly fee and no commitment. You can stop paying at any time, Your account will\nsimply be disabled when You run out of credits."), "\n", React.createElement(_components.li, null, "The first 50 credits of a new account are free of charge and act as a free trial period. Your\nwill never be charged automatically unless You explicitly allowed ", React.createElement(_components.abbr, {
    title: "Forwarder.cc"
  }, "Our service"), " to."), "\n"), "\n", React.createElement(_components.h3, null, "3. Refund Policy"), "\n", React.createElement(_components.p, null, "You may ask for a refund or free credits by sending an email to ", React.createElement(_components.a, {
    href: "mailto:say.hi@forwarder.cc"
  }, "say.hi@forwarder.cc"), ". The email\nneeds to contain any information required to identify Your account (email, name, address, etc.)."), "\n", React.createElement(_components.h3, null, "4. Cancellation and Termination of ", React.createElement(_components.abbr, {
    title: "Forwarder.cc"
  }, "Our service")), "\n", React.createElement(_components.p, null, "You may at any time close Your account. All Your data will be removed excluding anonymous ones. Any\nabuse of ", React.createElement(_components.abbr, {
    title: "Forwarder.cc"
  }, "Our service"), " will lead to termination of Your account. ", React.createElement(_components.abbr, {
    title: "Forwarder.cc"
  }, "We"), " reserve the right to decide what\nis considered an abuse."), "\n", React.createElement(_components.h3, null, "5. Changes to ", React.createElement(_components.abbr, {
    title: "This document"
  }, "Terms of Service")), "\n", React.createElement(_components.p, null, "These ", React.createElement(_components.abbr, {
    title: "This document"
  }, "Terms of Service"), " may be updated from time to time without prior notice. The current Terms of\nService are always available at\n", React.createElement(_components.a, {
    href: "https://forwarder.cc/us/policies/terms"
  }, "https://forwarder.cc/us/policies/terms"), "."), "\n", React.createElement(_components.h3, null, "6. Disclaimer"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.abbr, {
    title: "Forwarder.cc"
  }, "We"), " provide ", React.createElement(_components.abbr, {
    title: "Forwarder.cc"
  }, "Our service"), " \"as is\" and \"as available\"."), "\n", React.createElement(_components.li, null, React.createElement(_components.abbr, {
    title: "Forwarder.cc"
  }, "We"), " reserve the right to modify ", React.createElement(_components.abbr, {
    title: "Forwarder.cc"
  }, "Our service"), " at any time, including adding and removing features."), "\n", React.createElement(_components.li, null, React.createElement(_components.abbr, {
    title: "Forwarder.cc"
  }, "We"), " reserve the right to discontinue ", React.createElement(_components.abbr, {
    title: "Forwarder.cc"
  }, "Our service"), " at any time after a notice via email."), "\n", React.createElement(_components.li, null, React.createElement(_components.abbr, {
    title: "Forwarder.cc"
  }, "We"), " reserve the right to change the prices for ", React.createElement(_components.abbr, {
    title: "Forwarder.cc"
  }, "Our service"), " and any connected extra services at any\ntime."), "\n", React.createElement(_components.li, null, React.createElement(_components.abbr, {
    title: "Forwarder.cc"
  }, "We"), " give no warranties regarding the correctness of the data collected with ", React.createElement(_components.abbr, {
    title: "Forwarder.cc"
  }, "Our service"), " or any\npotential corruption or loss of such data."), "\n"), "\n", React.createElement(_components.h3, null, "7. Limitation of Liability"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "You expressly understand and agree that we shall not be liable for any damages resulting from the\nuse of ", React.createElement(_components.abbr, {
    title: "Forwarder.cc"
  }, "Our service"), "."), "\n", React.createElement(_components.li, null, "Any decisions or claims You make based on data from ", React.createElement(_components.abbr, {
    title: "Forwarder.cc"
  }, "Our service"), " are Your sole responsibility. ", React.createElement(_components.abbr, {
    title: "Forwarder.cc"
  }, "We"), "\nshall not be held liable for any such decisions or claims."), "\n", React.createElement(_components.li, null, "In no event shall ", React.createElement(_components.abbr, {
    title: "Forwarder.cc"
  }, "Our service"), "'s total liability to You for all damages, losses, and causes of\naction exceed the amount paid by You, if any, for accessing this site."), "\n"), "\n", React.createElement(_components.h3, null, "8. Indemnification"), "\n", React.createElement(_components.p, null, "You agree to indemnify, defend and hold harmless ", React.createElement(_components.abbr, {
    title: "Forwarder.cc"
  }, "Our service"), " and its officers, directors, employees,\nconsultants and agents from any and all third party claims, liability, damages and/or costs arising\nfrom Your use of ", React.createElement(_components.abbr, {
    title: "Forwarder.cc"
  }, "Our service"), ", Your violation of the terms of use or Your infringement, or\ninfringement by any other user of Your account, of any intellectual property or other right of any\nperson or entity."), "\n", React.createElement(_components.p, null, "You agree to immediately notify us of any unauthorized use of Your account or any other breach of\nsecurity known to You."), "\n", React.createElement(_components.h3, null, "9. Other"), "\n", React.createElement(_components.p, null, "If any part of this ", React.createElement(_components.abbr, {
    title: "This document"
  }, "Terms of Service"), " would be determined by any competent authority to be invalid,\nunlawful or unenforceable, the remainder of the ", React.createElement(_components.abbr, {
    title: "This document"
  }, "Terms of Service"), " shall continue to be valid and\nenforceable to the fullest extent permitted by law."), "\n", React.createElement(_components.h3, null, "10. Applicable law"), "\n", React.createElement(_components.p, null, "This contract shall be governed by the law of France. Any dispute, controversy or claim arising out\nof or in connection with this contract, or the breach, termination or invalidity thereof, shall be\nsolved by a Court in France."), "\n", "\n", "\n", "\n", "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
