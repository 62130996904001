import GATSBY_COMPILED_MDX from "/opt/render/project/src/website/src/content/us/policies/0-terms-and-conditions.mdx";
import React from "react";
import {Link} from "gatsby";
import {Page} from "../components/Page";
import {Wrapper} from "../components/App/Wrapper";
import {Breadcrumb} from "../components/Breadcrumb";
import "./Us.css";
function UsPage(_ref) {
  var _data$mdx$frontmatter, _data$mdx;
  var data = _ref.data, children = _ref.children;
  if (!data.mdx) {
    return;
  }
  var _ref2 = (_data$mdx$frontmatter = (_data$mdx = data.mdx) === null || _data$mdx === void 0 ? void 0 : _data$mdx.frontmatter) !== null && _data$mdx$frontmatter !== void 0 ? _data$mdx$frontmatter : {}, title = _ref2.title, lastUpdatedAt = _ref2.lastUpdatedAt, path = _ref2.path;
  var timeToRead = data.mdx.timeToRead;
  var pages = data.allMdx.edges;
  return React.createElement(Page, {
    className: "app-content"
  }, React.createElement(Wrapper, {
    pages: pages
  }, React.createElement("div", {
    className: "us-page"
  }, React.createElement(Breadcrumb, null, React.createElement(Link, {
    to: "/us"
  }, "Forwarder.cc"), path && React.createElement(Link, {
    to: path
  }, title)), React.createElement("div", {
    className: "us-page-header"
  }, React.createElement("h1", null, title), React.createElement("p", null, React.createElement("em", null, lastUpdatedAt, " \u2013 ", Math.ceil(timeToRead !== null && timeToRead !== void 0 ? timeToRead : 0), " mins read"))), children)));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(UsPage, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export var Head = function Head(_ref3) {
  var _data$mdx$frontmatter2, _data$mdx2;
  var data = _ref3.data;
  var _ref4 = (_data$mdx$frontmatter2 = (_data$mdx2 = data.mdx) === null || _data$mdx2 === void 0 ? void 0 : _data$mdx2.frontmatter) !== null && _data$mdx$frontmatter2 !== void 0 ? _data$mdx$frontmatter2 : {}, title = _ref4.title, path = _ref4.path;
  return React.createElement(React.Fragment, null, React.createElement("title", null, title + " \u2013 Forwarder.cc"), React.createElement("link", {
    rel: "canonical",
    href: "https://forwarder.cc" + path
  }));
};
var pageQuery = "2234059722";
